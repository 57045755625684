import { render, staticRenderFns } from "./rank-dashboard.vue?vue&type=template&id=6536630f&scoped=true&"
import script from "./rank-dashboard.vue?vue&type=script&lang=js&"
export * from "./rank-dashboard.vue?vue&type=script&lang=js&"
import style0 from "./rank-dashboard.vue?vue&type=style&index=0&id=6536630f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6536630f",
  null
  
)

export default component.exports