<template>
  <div class="page-wrap">
    <div class="page-list">
      <div
        class="card-wrap"
        style="padding: 10px 20px; margin-bottom: 6px;"
      >
        <Mrjsearch
          :formModel="searchParams"
          :columns="columns"
          :showSearchBtn="false"
          :showResetBtn="false"
        ></Mrjsearch>
      </div>
      <div
        class="card-wrap mgb-20"
        style="display: flex; height: 400px;"
      >
        <moneyDashboard
          style="width: 420px;"
          :moneyObj="moneyObj"
        ></moneyDashboard>
        <lineDashboard
          style="flex: 1; margin-left: 55px;"
          title="交易趋势图"
          :lineData="lineData"
        ></lineDashboard>
      </div>
      <a-row
        type="flex"
        justify="space-between"
      >
        <a-col
          flex="1"
          class="back0"
        >
          <div class="card-header">
            <span>{{title}}</span>
          </div>
          <a-table
            class="table-manage"
            :pagination="false"
            :columns="overViewColumns"
            :data-source="tableData"
          >
            <template
              slot="rank"
              slot-scope="action, record"
            >
              <span :class="[setRankClass(record.rank), 'rank']">{{ record.rank }}</span>
            </template>
          </a-table>
          <!-- <rankDashboard 
              ref="rank"
              title="销售排名前十商品"
              :listUrlApi="getAreaList" 
              :columns="overViewColumns" 
              :searchParams="searchParams" 
              ></rankDashboard> -->
        </a-col>
        <a-col style="width: 626px; margin-left: 20px;">
          <pieDashboard
            title="购买用户统计"
            :pieData="pieData"
          ></pieDashboard>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>

import rankDashboard from '@/views/mall/components/rank-dashboard'
import lineDashboard from '@/views/mall/components/line-dashboard.vue';
import pieDashboard from '@/views/mall/components/pie-dashboard.vue';
import moneyDashboard from '@/views/mall/components/money-dashboard.vue';
import Mrjsearch from '@/components/intimate/Mrjsearch'
import { getAreaList } from '@/api/reportForms'
import { prefixUnit } from '@/utils/utils'
export default {
  components: { lineDashboard, rankDashboard, pieDashboard, moneyDashboard, Mrjsearch },
  data() {
    return {
      getAreaList, // 这里是测试接口，实际请按真实接口填写
      overViewColumns: [ // 销售排名前十商品配置项
        {
          title: '排名',
          dataIndex: 'rank',
          width: 100,
          scopedSlots: { customRender: 'rank', slot: 'rank' }
        },
        {
          title: '商品名称',
          dataIndex: 'shop',
          ellipsis: true,
          width: 260,
          scopedSlots: { customRender: 'shop' }
        },
        {
          title: '商品售价',
          dataIndex: 'income',
          ellipsis: true,
          customRender: (text, record) => {
            return prefixUnit(record.income)
          },
          scopedSlots: { customRender: 'income' }
        },
        {
          title: '销量',
          dataIndex: 'commission',
          ellipsis: true,
          scopedSlots: { customRender: 'commission' }
        },
        {
          title: '销售额',
          dataIndex: 'introduction',
          ellipsis: true,
          customRender: (text, record) => {
            return prefixUnit(record.introduction)
          },
          scopedSlots: { customRender: 'introduction' }
        }
      ],
      searchParams: { // 搜索参数，请按真实字段配置
        shop: undefined,
        time: undefined,
        startTime: '',
        endTime: ''
      },
      columns: [ // 搜索配置项
        {
          type: 'select',
          prop: 'shop',
          width: '340px',
          placeholder: '全部门店',
          mode: 'multiple',
          allowClear: true,
          selectList: [
            {
              text: '武汉一店',
              value: 1
            },
            {
              text: '武汉二店',
              value: 2
            },
            {
              text: '武汉3店',
              value: 3
            }
          ],
          change: (val) => {
            // 选择后请求接口
            this.searchAll()
          }
        },
        {
          type: 'select',
          prop: 'time',
          width: '150px',
          placeholder: '请选择时间',
          allowClear: true,
          selectList: [
            {
              text: '本日',
              value: 1
            },
            {
              text: '本月',
              value: 2
            },
            {
              text: '本季度',
              value: 3
            },
            {
              text: '本年',
              value: 4
            },
            {
              text: '自定义时间',
              value: 5
            }
          ],
          change: (val) => {
            // 选择后请求接口
            console.log(val);
            this.columns[2].hide = val < 5
            if (val < 5) { // 这里需要请求下面数据接口
              this.searchAll()
            }
          }
        },
        {
          type: 'rangeDate',
          prop: 'range',
          width: '280px',
          hide: true,
          change: (date, dateString) => {
            this.searchParams.startTime = dateString[0]
            this.searchParams.endTime = dateString[1]
            // 传值时间，请求下面是数据接口
            this.searchAll()
          }
        },
      ],
      moneyObj: { // 顶部左侧数据，字段按真实数据配置
        total: 123423534,
        add: 13453,
        orderTotal: 1464545,
        addOrder: 13546,
        personTotal: 1678674,
        addPerson: 123
      },
      lineData: { // 右侧线性图表数据配置项
        columns: ['1月', '2月', '3月', '4月', '5月'],
        data1: [32371, 12328, 92381, 32371, 12328],
        data2: [19810, 4398, 52910, 19810, 4398],
        data3: [19810, 52910, 456, 567, 678]
      },
      pieData: {
        total: 123,
        data: [
          { name: "未消费用户", value: '23', itemStyle: { normal: { color: '#0BC7B9' }, emphasis: { color: '#0BC7B9' } } },
          { name: "消费1次用户", value: '32', itemStyle: { normal: { color: '#238BFF' }, emphasis: { color: '#238BFF' } } },
          { name: "消费2-5次用户", value: '12', itemStyle: { normal: { color: '#8A3DF7' }, emphasis: { color: '#8A3DF7' } } },
          { name: "消费6-10次用户", value: '8', itemStyle: { normal: { color: '#FD5437' }, emphasis: { color: '#FD5437' } } },
          { name: "消费11次以上", value: '1', itemStyle: { normal: { color: '#FD8C3E' }, emphasis: { color: '#FD8C3E' } } },
        ]
      },
      tableData: [
        {
          rank: 1,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 2,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 3,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 4,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 5,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 6,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 7,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 8,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }, {
          rank: 9,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }, {
          rank: 10,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40'],
        showSizeChanger: true,
        showQuickJumper: true
      },

    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    // 搜索选择后调用接口
    searchAll() {
      // 销售排名前十商品
      this.$refs.rank.search()
      // 其他接口
    },
    setRankClass(val) {
      return val > 3 ? 'rankOther' : 'rank' + val
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
.page-wrap {
  height: calc(100vh - 52px);
  overflow: auto;
}
.page-list {
  margin: 20px;
  border-radius: 2px;
  overflow: auto;
}
.rank1 {
  background: url("../../assets/images/xing2.png") no-repeat;
  color: #d0614a;
}
.rank2 {
  background: url("../../assets/images/xing3.png") no-repeat;
  color: #6bc0ba;
}
.rank3 {
  background: url("../../assets/images/xing4.png") no-repeat;
  color: #7c99a9;
}
.rankOther {
  background: url("../../assets/images/xing5.jpg") no-repeat;
  color: #7f8c8b;
}
.rank {
  width: 27px;
  height: 27px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  background-size: 27px 27px;
  font-family: "UDC";
}
.customTable {
  margin-top: 2px;
  /deep/.ant-table .ant-table-body .ant-table-thead > tr > th {
    box-shadow: none !important;
    border: none !important;
    background: rgba(236, 247, 245, 0.6);
    box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
    border-radius: 4px;
  }
  /deep/.ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  /deep/.ant-table-tbody tr:hover {
    td {
      background: #fff !important;
    }
  }
}
.back0 {
  background: #fff;
}
</style>

    