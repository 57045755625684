<!--
 * @Autor: liugang
 * @Date: 
 * 
-->
<template>
    <div class="card-wrap">
        <div class="card-header">
            <span>{{ title }}</span>
        </div>
        <div class="char-box">
            <div id="pie" style="height: 540px;"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        pieData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            chartExtend: {
                title: { // 中间文字
                    top: '35.5%',
                    left: '50%',
                    text: '用户数量（人）',
                    textStyle: {
                        color: 'rgba(45,56,53,0.8)',
                        fontSize: 14
                    },
                    subtext: this.pieData.total,
                    subtextStyle: {
                        fontSize: 24,
                        color: '#2D3835',
                        fontFamily: 'UDC'
                    },
                    show: true,
                    textAlign : 'center'
                },
                legend: {
                    bottom: 30,
                    icon: 'circle',
                    itemGap: 40,
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        color: '#5B5E5D',
                        fontSize: 14
                    }
                },
                series: {
                    type: 'pie',
                    data: this.pieData.data,
 
                    showEmptyCircle: true,
                    emptyCircleStyle: {
                        color: '#DADDDD' ,
                        opacity: 1
                    },
                    clockWise: false, // 逆时针旋转
                    radius: [90, 128],
                    center: ['50%', '40%'],
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 6
                    },
                    label: {
                        fontSize: 16,
                        color: '#2D3835',
                        formatter: '{c}人'
                    },
                    labelLine: {
                        length2: 30
                    }
                },
                // color: ['#0BC7B9', '#238BFF', '#8A3DF7', '#FD5437', '#FD8C3E']  
            }
        }
    },
    mounted () {
        this.myChart()
    },
    methods: {
        myChart() {
            const myChart = this.$echarts.init(document.getElementById('pie'));
            myChart.setOption(this.chartExtend)
        }
    },
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>