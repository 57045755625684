<!--
 * @Autor: liugang
 * @Date: 
 * 合伙人概况转介绍订单趋势
-->
<template>
    <div class="card-wrap">
        <div class="card-header">
            <span>{{ title }}</span>
        </div>
        <div class="char-box">
            <!-- <ve-line :data="lineData" height="300px"  :extend="chartExtend" :settings="settings"></ve-line> -->
            <div id="line" style="height: 310px;"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type:String,
            default: ''
        },
        lineData: { // 图表数据配置
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            chartExtend: {
                grid: {
                    top: 70,
                    left: 80,
                    right: 10,
                    bottom: 30
                },
                legend: {
                    show: true,
                    left: 26,
                    top: 5,
                    icon: 'circle',
                    itemGap: 40,
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        color: '#819190',
                        fontSize: 14
                    }
                },
                xAxis: {
                    show: true,
                    data: this.lineData.columns,
                    boundaryGap: false,
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            color: '#CAF2EF',
                            shadowColor: '#CAF2EF',
                            shadowBlur: 0.1,
                            opacity: 0.21
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#E8EDEC',
                            width: 2
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#9EA6A4',
                        fontSize: 14,
                        margin: 15,
                        lineHeight: 50
                    }
                },
                yAxis: {
                    name: '单位/元',
                    nameTextStyle: {
                        align: 'left',
                        fontSize: 14,
                        color: '#A4B3B1',
                        height: 600,
                        lineHeight: 60,
                        padding: [0, 0, 0, -65]
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#9EA6A4',
                        formatter: '¥{value}',
                        fontSize: 14,
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    padding: 10,
                    backgroundColor: '#FDFCFC',
                    borderWidth: 1,
                    borderColor: '#E1ECEC',
                    extraCssText:"box-shadow: 0px 2px 5px 0px rgba(147,209,210,0.31);",
                    textStyle: {
                        color: '#606463'
                    }
                },
                series: [
                    {
                        type: 'line',
                        data: this.lineData.data1,
                        name: '自提商品',
                        smooth: false,
                        showSymbol: false,
                        symbolSize: 6,
                        animation: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(109, 219, 212, 0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(109, 219, 212, 0)'
                            }
                            ])
                        },
                    },
                    {
                        type: 'line',
                        data: this.lineData.data2,
                        name: '到店核销',
                        smooth: false,
                        showSymbol: false,
                        symbolSize: 6,
                        animation: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(59, 151, 255, 0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(59, 151, 255, 0)'
                            }
                            ])
                        },
                    },
                    {
                        type: 'line',
                        data: this.lineData.data3,
                        name: '快递订单',
                        smooth: false,
                        showSymbol: false,
                        symbolSize: 6,
                        animation: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(255, 135, 124, 0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(255, 135, 124, 0)'
                            }
                            ])
                        },
                    }
                ],
                color: ['#0BC7B9', '#238BFF', '#FF6D6D']  
            }
        }
    },
    mounted () {
        this.myChart()
    },
    methods: {
        myChart() {
            const myChart = this.$echarts.init(document.getElementById('line'));
            myChart.setOption(this.chartExtend)
        }
    },
}
</script>

<style lang="less" scoped>
@import '../index.less';
.card-wrap {
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .card-header {
        border-bottom: none;
        height: 30px;
        line-height: 30px;
        margin-top: 22px;
        &::before {
            top: 13px;
        }
    }
}
</style>