<!--
 * @Autor: liugang
 * @Date: 2023-04-04 14:22:31
 * 合伙人概况总览，转介绍数据看板
-->
<template>
    <div class="money-wrap">
        <div class="money-box">
            <p class="title">订单总额（元）</p>
            <h3 class="total"><a-statistic :value="moneyObj.total" :valueStyle="{
                'font-size': '32px',
                'line-height': '38px'
                }" /></h3>
            <p class="add">新增金额 <span>+<a-statistic style="display: inline-block;" :value="moneyObj.add" :valueStyle="{
                'font-size': '18px',
                'font-weight': 'bold',
                color: '#131010'
                }"/></span></p>
            <img class="img" src="@/assets/images/xing1.png" alt="">
        </div>
        <ul class="list">
            <li>
                <p class="title">订单总量（单）</p>
                <p class="num"><a-statistic style="display: inline-block;" :value="moneyObj.orderTotal"/></p>
            </li>
            <li>
                <p class="title">新增订单（单）</p>
                <p class="num"><a-statistic style="display: inline-block;" :value="moneyObj.addOrder"/></p>
            </li>
            <li>
                <p class="title">累计用户（人）</p>
                <p class="num"><a-statistic style="display: inline-block;" :value="moneyObj.personTotal"/></p>
            </li>
            <li>
                <p class="title">新增用户（人）</p>
                <p class="num"><a-statistic style="display: inline-block;" :value="moneyObj.addPerson" /></p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        moneyObj: { // 上部分数据对象
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data(){
        return{

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>

.money-wrap {
    .money-box {
        height: 138px;
        background: #F6FEFF;
        border-radius: 8px;
        border: 1px solid #D9E7E8;
        padding: 20px 24px;
        margin-top: 24px;
        position: relative;
        .title {
            color: rgba(49,80,78,0.8);
        }
        .total {
            margin-bottom: 10px;
            margin-top: 4px;
            /deep/.ant-statistic .ant-statistic-content {
                color: @primaryColor
            }
        }
        .add {
            color: rgba(45,56,53,0.8);
            span {
                font-weight: bold;
                color: #131010;
                font-family: 'UDC';
                font-size: 18px;
            }
        }
        .img {
            width: 61px;
            height: 56px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .list {
        padding: 0;
        li {
            width: 50%;
            padding-top: 35px;
            display: inline-block;
            .title {
                color: rgba(45,56,53,0.8);
                line-height: 20px;
            }
            .num {
                /deep/.ant-statistic .ant-statistic-content {
                    font-weight: bold;
                    color: #131010;
                    line-height: 29px;
                    font-size: 24px;
                    font-family: 'UDC';
                    margin-top: 8px;
                }
                
            }
        }
        
    }
}

</style>