<!--
 * @Autor: liugang
 * @Date: 
 *  
-->
<template>
  <div class="card-wrap">
    <div class="card-header">
      <span>{{title}}</span>
    </div>
    <div class="rank-wrap">
      <MrjTable
        ref="table"
        class="detailsTable customTable"
        :columns="columns"
        :data-source="tableData"
        :queryParams="searchParams"
        :pagination="false"
        row-key="rank"
        :listUrlApi="listUrlApi"
        @listCallBack="listSuccess"
      >
        <!--   :listUrlApi="listUrlApi" -->
        <template #rank="{text, scope}">
          <span :class="[setRankClass(scope.rank), 'rank']">{{ scope.rank }}</span>
        </template>
      </MrjTable>
    </div>
  </div>
</template>

<script>
import MrjTable from '@/components/intimate/Mrjtable'
export default {
  components: { MrjTable },
  props: {
    title: {
      type: String,
      default: ''
    },
    listUrlApi: { // 表格请求接口
      type: Function,
      default: () => {
        return null
      }
    },
    columns: { // 表格配置项
      type: Array,
      default: () => {
        return []
      }
    },
    searchParams: { // 搜索参数
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      tableData: []
    }
  },

  methods: {
    // 生成排名class
    setRankClass(val) {
      return val > 3 ? 'rankOther' : 'rank' + val
    },

    // 父级调用这个方法请求列表接口
    search() {
      this.$refs.table.getList(1)
    },

    // 列表请求成功
    listSuccess(res) {
      this.tableData = [
        {
          rank: 1,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 2,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 3,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 4,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 5,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 6,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 7,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        },
        {
          rank: 8,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }, {
          rank: 9,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }, {
          rank: 10,
          shop: '湖北省武汉市1号店',
          income: 23423,
          commission: 54645,
          introduction: 234,
          person: '张三',
          phone: '13888881234'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import "../index.less";

.rank1 {
  background: url("../../../assets/images/xing2.png") no-repeat;
  color: #d0614a;
}
.rank2 {
  background: url("../../../assets/images/xing3.png") no-repeat;
  color: #6bc0ba;
}
.rank3 {
  background: url("../../../assets/images/xing4.png") no-repeat;
  color: #7c99a9;
}
.rankOther {
  background: url("../../../assets/images/xing5.jpg") no-repeat;
  color: #7f8c8b;
}
.rank {
  width: 27px;
  height: 27px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  background-size: 27px 27px;
  font-family: "UDC";
}
.customTable {
  margin-top: 2px;
  /deep/.ant-table .ant-table-body .ant-table-thead > tr > th {
    box-shadow: none !important;
    border: none !important;
    background: rgba(236, 247, 245, 0.6);
    box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
    border-radius: 4px;
  }
  /deep/.ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  /deep/.ant-table-tbody tr:hover {
    td {
      background: #fff !important;
    }
  }
}
</style>