<!-- 
    公共表格组件
    lastEditor: liugang
    lastUpdateTime: 2023-6-8
 -->
 <template>
    <a-table
        class="table-manage"
        v-bind="$attrs" 
        v-on="$listeners"
        :loading="loading"
        :pagination="pagination"
        @change="handleChange"
    >
        <a-table-column v-for="column in $attrs.columns" 
        :key="column.dataIndex" 
        :title="column.title" 
        :data-index="column.dataIndex" >
    </a-table-column>
        <!-- 表头插槽解析 -->
        <template  v-for="column in $attrs.columns"  :slot="column.slots ? column.slots.title : ''" >
            <template v-if="column.slots ">
                <template v-if="column.slots.tooltip ">
                    <a-tooltip placement="top" v-if="column.slots.tooltip">
                        <template slot="title">
                            <p>{{ column.slots.tooltip }}</p>
                        </template>
                        {{column.slots.head}}<a-icon class="icon" :type="column.slots.icon ? column.slots.icon : 'info-circle'" />
                    </a-tooltip>
                    <span v-else>{{column.slots.head}}</span>
                </template>
                <template v-else-if="column.slots.search" >

                    <a-dropdown v-if=" column.slots.type && column.slots.type === 'select'" overlayClassName="customDropDown" :trigger="['click']">
                        <span class="cursor-pointer hoverClass bigdropdown" :class="{active: column.slots.show}" @click="e => e.preventDefault()">
                            <i v-if="column.slots.prefixIcon" :style="{'font-size': column.slots.prefixFontSeze || '12px'}" :class="['icons meiye-icon', column.slots.prefixIcon]"></i>
                            {{column.slots.head}}
                            <i class="meiye-icon meiye-moren1" style="font-size: 16px; vertical-align: middle;"></i>
                        </span>
                        <div slot="overlay">
                        <slot :name="column.slots.slot" v-bind:scope="column.slots"></slot>
                    </div>
                    </a-dropdown>

                    <a-popover v-else v-model="column.slots.show" placement="bottomLeft" trigger="click">
                        <span class="cursor-pointer hoverClass bigdropdown" :class="{active: column.slots.show}" @click.stop="column.slots.show = true">
                            <i v-if="column.slots.prefixIcon" :style="{'font-size': column.slots.prefixFontSeze || '12px'}" :class="['icons meiye-icon', column.slots.prefixIcon]"></i>
                            {{column.slots.head}}
                            <i class="meiye-icon meiye-moren1" style="font-size: 16px; vertical-align: middle;"></i>
                        </span>
                        <a slot="content">
                            <slot :name="column.slots.slot" v-bind:scope="column.slots"></slot>
                        </a> 
                    </a-popover>
                    
                </template>
                <slot v-else :name="column.slots.title"></slot>
            </template>
        </template>
        <!-- 解析插槽 -->
        <template  v-for="column in $attrs.columns"  :slot="column.scopedSlots ? column.scopedSlots.customRender : ''" slot-scope="text,record" >
            <!-- customRender="operate" 固定表示操作栏插槽 -->
            <template v-if="column.scopedSlots?.customRender === 'operate'">
                <slot :name="column.scopedSlots ? column.scopedSlots.customRender : ''" v-bind:scope="record" ></slot>
            </template>
            <template v-else>
                <!-- 这里区分插槽和默认值， scopedSlots添加slot参数表示使用插槽展示，否则直接展示-->
                <template v-if="column.scopedSlots.slot">
                    <slot :name="column.scopedSlots ? column.scopedSlots.customRender : ''" v-bind:scope="record" ></slot>
                </template>
                <span v-else :title="text">{{ text || text === 0 ? text : '--' }}</span>
            </template>
            
        </template>
    </a-table>
</template>

<script>
import { isType, filterObj } from '@/utils/utils'
export default {
    name: 'tooltip',
    props: {
        listUrlApi: {  // 表格数据请求接口
            type: Function,
            default: () => {
                return null
            }
        },
        queryParams: { // 列表请求除了分页信息的传参对象
            type: Object,
            default: () => {
                return {}
            }
        },
        defaultSearch: { // 是否默认搜索,传入false 表示页面手动调用搜索函数
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading:false,
            renderArr: [],
            defaultPagination: {
                current: 1,
                pageSize: 10, 
                total: 0, 
                pageSizeOptions: ['10','20','30','40'],
                showSizeChanger: true, 
                showQuickJumper: true
            },
            listUrl: null
        };
    },

    computed: {
        pagination() { // 计算当前页码信息，得到最新的页码
            let resultPagination = this.defaultPagination
            if(isType(this.$attrs.pagination, 'Boolean') && !this.$attrs.pagination){ // 不需要分页
                resultPagination = this.$attrs.pagination
            }else if(isType(this.$attrs.pagination, 'Object')){ // 合并修改分页参数
                resultPagination = Object.assign(resultPagination, this.$attrs.pagination)
            }
            return resultPagination
        }
    },

    watch: {
        listUrlApi: { // 切换接口需要深度监听
            immediate: true,
            deep: true,
            handler(url) {
                this.listUrl = url
            }
        }
    },

    created() {
        this.renderArr = this.$slots.default
    },

    mounted() {
        if(this.defaultSearch) {
            this.getList(1)
        }
    },

    methods: {
        // 翻页请求
        handleChange(pagination, filter, sorter) {
            this.defaultPagination.current = pagination.current
            this.defaultPagination.pageSize = pagination.pageSize
            this.$emit('filter', filter)
            this.$emit('sort', sorter)
            this.getList()
        },

        // 获取数据
        getList(page) {
            if(page) {
                this.defaultPagination.current = page
            }
            this.loading = true
            const params = {
                ...this.queryParams
            }
            if(this.pagination) { // 判断有分页的时候才传
                params.page = this.pagination.current
                params.per_page = this.pagination.pageSize
            }
            this.listUrl(filterObj(params)).then((res) => {
                if(res.status === 'success') {
                    this.defaultPagination.total = res.data.total
                    // 请求成功后的回调
                    this.$emit('listCallBack', res.data)
                }
            }).finally(() => {
                this.loading = false
            })
        }
    },
};
</script>

<style lang="less" scoped>
.table-manage {
    .meiye-moren1 {
        transform: rotate(0);
        transition: transform 0.35s ease 0s;
        display: inline-block;
    }
    .active {
        background: #f2f5f5;
        border-radius: 4px;
        .meiye-moren1 {
            transform: rotate(180deg);
        }
    }
    /deep/.ant-table .ant-table-fixed-left {
        top: 0;
    }
    /deep/ .ant-table .ant-table-fixed-right {
        top: 0;
    }

    .bigdropdown {
        line-height: 26px;
        padding: 0 8px;
        display: inline-block;
        &:hover {
            background: #f2f5f5;
            border-radius: 4px;
        }
    }
}

.detailsTable {
    .bigdropdown {
        &:hover {
            background: #E4EBE9;
        }
    }
}

.icons {
    color: #819190;
}
.icon {
    margin-left: 4px;
    color: #819190;
}
</style>