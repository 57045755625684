/*
 * @Version: 2.0
 * @Autor: liugang
 * @Date: 2023-06-14 9:09:00
 * @LastEditors: liugang
 * @LastEditTime:
 */
import { post, get } from '@/utils/requesthhr'
import requesthhr from '@/utils/requesthhr'

/** ****************************  报表 》公共接口  ************************************/
// 获取所有门店列表
export function allShop (data) {
  return get({
    url: '/api/bi/v1/report/common/shop/list',
    data: data
  })
}
// 获取所有员工列表
export function allUser (data) {
  return get({
    url: '/api/bi/v1/report/common/organization/user/list',
    data: data
  })
}
// 获取产品列表
export function getProducts (data) {
  return get({
    url: '/api/bi/v1/report/common/product/list',
    data: data
  })
}

// 获取品牌列表
export function getBrands (data) {
  return get({
    url: '/api/bi/v1/report/common/product/brand/list',
    data: data
  })
}
// 获取品类列表 （树形结构）
export function getCategorys (data) {
  return get({
    url: '/api/v2/manager/productnew/category_tree',
    data: data
  })
}
// 根据位置id获取品项列表
export function getProductItemList (data) {
  return get({
    url: '/api/bi/v1/report/common/product/item/list',
    data: data
  })
}
// 区域列表（当前登录员工获得授权）
export function getAllArea (data) {
  return get({
    url: '/api/bi/v1/report/common/search/area/list',
    data: data
  })
}
// 根据区域获取门店列表
export function getAreaToShop (data) {
  return get({
    url: '/api/bi/v1/report/common/search/area/shop/list',
    data: data
  })
}
/** ****************************  报表》区域设置接口  ************************************/
// 获取区域列表
export function getAreaList (data) {
  return get({
    url: '/api/bi/v1/report/area/list',
    params: data
  })
}
// 创建区域
export function createArea (data) {
  return post({
    url: '/api/bi/v1/report/area/create',
    data: data
  })
}
// 编辑区域/批量编辑区域
export function editArea (data) {
  return post({
    url: '/api/bi/v1/report/area/edit',
    data: data
  })
}
// 删除区域/批量删除区域
export function delArea (data) {
  return post({
    url: '/api/bi/v1/report/area/del',
    data: data
  })
}

/** ****************************  报表》报表字段配置接口  ************************************/
// 获取报表字段列表
export function getFieldList (data) {
  return get({
    url: '/api/bi/v1/report/field/list',
    data: data
  })
}
// 创建报表字段
export function createField (data) {
  return post({
    url: '/api/bi/v1/report/field/create',
    data: data
  })
}
// 编辑报表字段
export function editField (data) {
  return post({
    url: '/api/bi/v1/report/field/edit',
    data: data
  })
}
// 删除报表字段/批量删除报表字段
export function delField (data) {
  return post({
    url: '/api/bi/v1/report/field/del',
    data: data
  })
}
// 字段复制/批量复制
export function copyField (data) {
  return post({
    url: '/api/bi/v1/report/field/copy',
    data: data
  })
}
// 获取表报页面列表（支持动态参数的页面）
export function getFieldPageList (data) {
  return get({
    url: '/api/bi/v1/report/page/list',
    data: data
  })
}
// 获取字段添加位置
export function getLocationList (data) {
  return get({
    url: '/api/bi/v1/report/page/location/list',
    data: data
  })
}
/** ****************************  报表》营业报表接口  ************************************/
// 列表头部数据接口
export function getTradeStatistics (data) {
  return get({
    url: '/api/bi/v1/head/trade/statistics',
    data: data
  })
}
// 详情头部数据接口
export function getTradeDetailStatistics (data) {
  return get({
    url: '/api/bi/v1/head/trade/detail/statistics',
    data: data
  })
}
// 表格模式-欠款明细列表
export function getArrearsList (data) {
  return get({
    url: '/api/bi/v1/head/trade/arrears/list',
    data: data
  })
}
// 表格模式-欠款明细导出
export function arrearsExport (data,headerType) {
  return post({
    url: '/api/bi/v1/head/trade/arrears/export',
    data: data
  }, headerType)
}
// 列表-按照门店维度
export function getTradeList (data) {
  return get({
    url: '/api/bi/v1/head/trade/index',
    data: data
  })
}
// 客次统计列表
export function getResidenceList (data) {
  return get({
    url: '/api/bi/v1/head/trade/residence/index',
    data: data
  })
}
// 收入统计列表
export function getIncomeList (data) {
  return get({
    url: '/api/bi/v1/head/trade/income/index',
    data: data
  })
}
// 品项金额列表
export function getTradeItemList (data) {
  return get({
    url: '/api/bi/v1/head/trade/item/index',
    data: data
  })
}
// 消耗统计列表
export function getConsumeList (data) {
  return get({
    url: '/api/bi/v1/head/trade/consume/index',
    data: data
  })
}

/** ****************************  报表》未消费统计接口  ************************************/
// 未消耗统计列表
export function getUnconsumeList (data, headerType) {
  return get({
    url: '/api/bi/v1/report/unconsumed/stat',
    data: data,
  },headerType)
}
// 获取未消耗统计数据
export function getUnconsumeData (data) {
  return get({
    url: '/api/bi/v1/report/unconsumed/data',
    data: data
  })
}
// 未消耗统计导出
export function unconsumeExport (data) {
  return get({
    url: '/api/bi/v1/report/unconsumed/stat/export',
    data: data
  })
}
// 未消耗明细列表
export function getUnconsumeDetailList (data, headerType) {
  return get({
    url: '/api/bi/v1/report/unconsumed/stat/list',
    data: data
  }, headerType)
}
// 未消耗明细统计导出
export function unconsumeDetailExport (data) {
  return get({
    url: '/api/bi/v1/report/unconsumed/stat/list/export',
    data: data
  })
}

/** ****************************  报表》客户消费统计接口  ************************************/

/** ****************************  报表》品项统计接口  ************************************/
// 获取报表字段列表
export function getItemList (data, headerType) {
  return get({
    url: '/api/bi/v1/report/item/list',
    data: data
  }, headerType)
}
// 品项统计导出
// export function itemExport (data) {
//   return get({
//     url: '/api/bi/v1/report/item/list/export',
//     data: data
//   })
// }
// 品项统计-门店统计
export function itemShopStatistics (data) {
  return get({
    url: '/api/bi/v1/report/item/list/shop',
    data: data
  })
}
// 品项统计-员工统计
export function itemEmployeeStatistics (data) {
  return get({
    url: '/api/bi/v1/report/item/list/employee',
    data: data
  })
}
// 品项统计-明细列表
export function getItemDetailList (data) {
  return get({
    url: '/api/bi/v1/report/item/list/view/list',
    data: data
  })
}

/** ****************************  报表》品项销售排名接口  ************************************/

/** ****************************  报表》品项销售统计接口  ************************************/
