var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"money-wrap"},[_c('div',{staticClass:"money-box"},[_c('p',{staticClass:"title"},[_vm._v("订单总额（元）")]),_c('h3',{staticClass:"total"},[_c('a-statistic',{attrs:{"value":_vm.moneyObj.total,"valueStyle":{
            'font-size': '32px',
            'line-height': '38px'
            }}})],1),_c('p',{staticClass:"add"},[_vm._v("新增金额 "),_c('span',[_vm._v("+"),_c('a-statistic',{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.moneyObj.add,"valueStyle":{
            'font-size': '18px',
            'font-weight': 'bold',
            color: '#131010'
            }}})],1)]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/xing1.png"),"alt":""}})]),_c('ul',{staticClass:"list"},[_c('li',[_c('p',{staticClass:"title"},[_vm._v("订单总量（单）")]),_c('p',{staticClass:"num"},[_c('a-statistic',{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.moneyObj.orderTotal}})],1)]),_c('li',[_c('p',{staticClass:"title"},[_vm._v("新增订单（单）")]),_c('p',{staticClass:"num"},[_c('a-statistic',{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.moneyObj.addOrder}})],1)]),_c('li',[_c('p',{staticClass:"title"},[_vm._v("累计用户（人）")]),_c('p',{staticClass:"num"},[_c('a-statistic',{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.moneyObj.personTotal}})],1)]),_c('li',[_c('p',{staticClass:"title"},[_vm._v("新增用户（人）")]),_c('p',{staticClass:"num"},[_c('a-statistic',{staticStyle:{"display":"inline-block"},attrs:{"value":_vm.moneyObj.addPerson}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }