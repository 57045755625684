<!-- 
    公共搜索组件
    liugang
    columns: [
        {
            type: 'input',  // 表单类型，可选input，select，radio，checkbox，datePicker等
            label: '表单label',
            prop: '表单prop',
            rules: [], // 表单校验规则
            selectList: [], // 下拉框数据
            mode: 'defalut', // 选择模式，多选填multiple
            allowClear:false, // 是否支持清除
            dictValue: 'value', // 下拉框下拉值value名称,默认value
            dictName: 'name', // 下拉框下拉值label名称, 默认name
            disabled: false, // 是否禁用
            hide: false, // 是否隐藏，默认为false
            change: (value) => {// change方法}
        }
    ]
 -->
 <template>
    <a-form-model class="myForm" v-bind="$attrs" v-on="$listeners" ref="form" layout="inline" :model="formModel">
        <a-form-model-item v-for="item in columns" :key="item.prop">
            <!-- 下拉框 -->
            <template v-if="item.type === 'select' && !item.hide">
                <ak-select
                        ref="select"
                        class="custom-select"
                        :style="{width: item.width || '180px'}"
                        :fieldKey="{value: item.dictValue || 'value', label: item.dictName || 'text'}" 
                        :loadData="item.selectList" 
                        :mode="item.mode" 
                        showArrow
                        :allowClear="item.allowClear || true" 
                        :disabled="item.disabled"
                        :maxTagCount="item.maxTagCount || 2"
                        :placeholder="item.placeholder || '请选择'"
                        v-model="formModel[item.prop]" 
                        @change="item.change && item.change($event)"
                        @deselect="item.deselect && item.deselect($event)"
                        @select="item.select && item.select($event)"
                         >
                    </ak-select>
            </template>
            <!-- 下拉框 -->
            <template v-if="item.type === 'input' && !item.hide">
                <a-input class="ant-input_2"
                        :allowClear="item.allowClear || true" 
                        :disabled="item.disabled"
                        :placeholder="item.placeholder || '请输入'"
                        v-model="formModel[item.prop]" 
                        @change="item.change && item.change($event)" />
            </template>
            <!-- 时间区间选择框 -->
            <template v-if="item.type === 'rangeDate' && !item.hide">
                <a-range-picker 
                        :style="{width: item.width || '300px'}"
                        :format="item.format || 'YYYY-MM-DD'"
                        :mode="item.mode || ['date', 'date']"
                        :disabled-date="item.disabledDate"
                        :disabled-time="item.disabledTime"
                        :allowClear="item.allowClear || true" 
                        :show-time="item.showTime"
                        :disabled="item.disabled"
                        :placeholder="item.placeholder"
                        v-model="formModel[item.prop]"  
                        @change="item.change" 
                        @panelChange="item.panelChange && item.panelChange($event)">
                        <i style="margin-top: -7px; font-size: 14px;" slot="suffixIcon" class="meiye-icon meiye-rili-moren"></i>
                    </a-range-picker>
            </template>
            <!-- 组合下拉选择框 -->
            <template v-if="item.type === 'selectGroup' && !item.hide">
                <a-input-group compact :style="{width: item.width || '300px'}">
                    <template v-for="(group, index) in item.groupArr">
                        <ak-select 
                            class="custom-select"
                            :style="{width: group.width || '50%'}"
                            :fieldKey="{value: group.dictValue || 'value', label: group.dictName || 'text'}" 
                            :loadData="group.selectList" 
                            :mode="group.mode" 
                            showArrow
                            :allowClear="group.allowClear || true" 
                            :disabled="group.disabled"
                            :placeholder="group.placeholder || '请选择'"
                            v-model="formModel[group.prop]" 
                            @change="group.change && group.change($event)" />
                    </template>
                </a-input-group>
            </template>

           <!-- 组合下拉和文本框 -->
           <template v-if="item.type === 'selectInputGroup' && !item.hide">
                <a-input-group compact :style="{width: item.width || '300px'}">
                    <ak-select 
                        class="custom-select"
                        :style="{width: item.groupArr[0].width || '40%'}"
                        :fieldKey="{value: item.groupArr[0].dictValue || 'value', label: item.groupArr[0].dictName || 'text'}" 
                        :loadData="item.groupArr[0].selectList" 
                        :mode="item.groupArr[0].mode" 
                        showArrow
                        :allowClear="item.groupArr[0].allowClear || true" 
                        :disabled="item.groupArr[0].disabled"
                        :placeholder="item.groupArr[0].placeholder || '请选择'"
                        v-model="formModel[item.groupArr[0].prop]" 
                        @change="item.groupArr[0].change && item.groupArr[0].change($event)" />
                        <a-input 
                            class="ant-input_2"
                            :style="{width: item.groupArr[1].width || '60%'}"
                            :allowClear="item.groupArr[1].allowClear || true" 
                            :disabled="item.groupArr[1].disabled"
                            :placeholder="item.groupArr[1].placeholder || '请输入'"
                            v-model="formModel[item.groupArr[1].prop]" 
                            @change="item.groupArr[1].change && item.groupArr[1].change($event)" />
                </a-input-group>
            </template>
            <!-- 组合下拉框和日期选择框 -->
           <template v-if="item.type === 'selectRangDateGroup' && !item.hide">
                <a-input-group compact :style="{width: item.width || '400px'}">
                    <ak-select 
                        class="custom-select"
                        :style="{width: item.groupArr[0].width || '35%'}"
                        :fieldKey="{value: item.groupArr[0].dictValue || 'value', label: item.groupArr[0].dictName || 'text'}" 
                        :loadData="item.groupArr[0].selectList" 
                        :mode="item.groupArr[0].mode" 
                        showArrow
                        :allowClear="item.groupArr[0].allowClear || true" 
                        :disabled="item.groupArr[0].disabled"
                        :placeholder="item.groupArr[0].placeholder || '请选择'"
                        v-model="formModel[item.groupArr[0].prop]" 
                        @change="item.groupArr[0].change && item.groupArr[0].change($event)" />
                        <a-range-picker 
                            :style="{width: item.groupArr[1].width || '65%'}"
                            :format="item.groupArr[1].format || 'YYYY-MM-DD'"
                            :disabled-date="item.groupArr[1].disabledDate"
                            :disabled-time="item.groupArr[1].disabledTime"
                            :allowClear="item.groupArr[1].allowClear || true"
                            :show-time="item.groupArr[1].showTime"
                            :disabled="item.groupArr[1].disabled"
                            :placeholder="item.groupArr[1].placeholder"
                            v-model="formModel[item.groupArr[1].prop]"  
                            @change="item.groupArr[1].change && item.groupArr[1].change($event)" />
                </a-input-group>
            </template>
            <!-- 自定义插槽 -->
            <template v-if="item.slot">
                <slot :name="item.slot"></slot>
            </template>
          </a-form-model-item>
          <a-form-model-item >
            <!-- 操作按钮插槽 -->
            <a-button v-if="showSearchBtn" class="table-btn-clk" style="margin-left: 0;"  type="primary"  @click="search" >查询</a-button>
            <a-button v-if="showResetBtn" class="table-btn-clk" type="link" style="margin-left: 16px;"  @click="reset" >重置</a-button>
          </a-form-model-item>
    </a-form-model>
</template>

<script>
export default {
    name: 'baseForm',
    props: {
        formModel: { // 表单字段对象
            type: Object, 
            default: () => {
                return {}
            }
        },
        columns: { // 表单元素配置项
            type: Array,
            default: () => {
                return []
            }
        },
        showSearchBtn: { // 是否显示搜索按钮
            type: Boolean,
            default: true
        },
        showResetBtn: { // 是否显示重置按钮
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        // 搜索
        search() {
            this.$emit('search')
        },

        // 重置
        reset() {
            this.$emit('reset')
        }
    },
};
</script>

<style lang="less" scoped>
.myForm {
    /deep/.ant-select-selection--multiple .ant-select-selection__choice__content {
        max-width: 50px;
    }
}
</style>